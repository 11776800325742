<template>
<div>
  <div class="m-4">
    <b-checkbox v-model="forceCanvas">Force Canvas</b-checkbox>
    <b-checkbox v-model="debug">Debug</b-checkbox>
  </div>
  <div class="break-out d-flex flex-column align-content-between flex-wrap justify-content-center h-100 embed-responsive embed-responsive-16by9">
    <composer
      class="embed-responsive-item"
      ref="composer"
      :template="template"
      :override-assets="overrideAssets"
      :layout-id="layoutSelect"
      :config="{forceCanvas, debug}"
    />
  </div>
  <webrtc-2 
    :user="{id:'test'}"
    :room="{id:'test', video:true, startMuted:true, users:['test']}"
    @usersChange="webrtcUsersUpdate"
  />
</div>
</template>

<script>
import { db } from "@/services/db";
import Composer from '@/components/composer.vue'
import Webrtc2 from '@/components/webrtc2.vue'
import { getLog } from "@/services/log";
let log = getLog("composer");

export default {
  components: {
    Composer,
    Webrtc2
  },
  data() {
    return {
      template: null,
      layoutSelect: "split",
      users: [],
      overrideAssets: {},

      // Parameters
      forceCanvas: false,
      debug: true,
    }
  },
  async mounted() {
    await this.$bind("template", db.collection("LiveStudioTemplates").doc("landscape-480p-background"));
  },
  methods: {
    webrtcUsersUpdate(users) {
      log.log("webrtcUsersUpdate", users);
      this.users = users;
      this.overrideAssets["streamA"] = { stream: users[0].stream };     
    },
  }
}
</script>

<style>

</style>